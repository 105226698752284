.emoji-box-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 0.25rem;
}

.emoji-title-p {
    margin: 0;
    padding: 0;
    color: #919191;
    text-align: center;
    font-family: "Inter";
    font-size: 0.85rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.emoji-title-selected-p {
    margin: 0;
    padding: 0;
    color: #FFF;
    text-align: center;
    font-family: "Inter";
    font-size: 0.85rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.emoji-div-selected {
    display: flex;
    width: 4.5rem;
    height: 4.5rem;
    padding: 0.1rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 31.25rem;
    border: 2px solid var(--Blinker-Pink, #919191);
    /* background: var(--Black, #0C0C0C); */
}

.emoji-div {
    display: flex;
    width: 4.5rem;
    height: 4.5rem;
    padding: 0.1rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 31.25rem;
    border: 2px solid;
    border-color: transparent;
}

.emoji-box-image {
    width: 3rem;
    height: 3rem;
    flex-shrink: 0;
}