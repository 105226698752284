.app-camera-main-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding-top: 5rem;
    max-width: 100vw;
}

.app-camera-buttons-div {
    width: 85%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
}


.switch-app-camera-button {
    display: flex;
    width: 3.375rem;
    height: 3.375rem;
    flex-shrink: 0;
    background-color: #1B1B1C;
    border: none;
    border-radius: 31.25rem;
    align-items: center;
    justify-content: center;
}

.switch-app-camera-hidden {
    width: 3.375rem;
    height: 3.375rem;
    flex-shrink: 0;
    background-color: #0C0C0C;
    border: none;
}

.reverse-app-camera-icon {
    width: 2rem;
    height: 2rem;
    flex-shrink: 0;
}