.checkin-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
}

.qrcode-div {
    position: fixed;
    bottom: 2.94rem;
    right: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}