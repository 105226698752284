.menu-perfil-div {
    display: flex;
    width: 10.5rem;
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 0.875rem;
}

.menu-perfil-photo-container {
    position: relative;
}

.menu-perfil-photo {
    height: 7.63rem;
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
    border-radius: 31.25rem;
    width: 7.63rem;
    border: 3px solid var(--Field-Active, #4E4D4D);
    object-fit: cover;

}

.menu-perfil-name {
    margin: 0;
    align-self: stretch;
    color: var(--White, var(--Highlight---text-on-highlight, #FFF));
    text-align: center;
    font-family: "Work Sans";
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.menu-camera-background-counter {
    position: absolute;
    top: -0.18rem;
    right: -0.2rem;
    display: inline-flex;
    width: 1rem;
    height: 1rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 31.25rem;
    border: 1px solid #1B1B1C;
    background: #FF6D8B;
    cursor: pointer;
}

.notification-counter {
    color: #1B1B1C;
    font-family: "Work Sans";
    font-size: 0.70rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.menu-camera-icon-container {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: #FF69B4;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    border-radius: 31.25rem;
    border: 3px solid var(--BG-RESPOSTAS, #262627);
    background: var(--Blinker-Pink, #EB7A91);

}

.menu-camera-icon {
    width: 1.525rem;
    height: 1.19375rem;
    flex-shrink: 0;
}

.menu-location-div {
    display: flex;
    margin-inline: 1.08rem;
    margin-top: 2.25rem;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    border-radius: 1rem 1rem 0 0;
    background: #363636;
}


.menu-center-div {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    flex-shrink: 0;
    flex: 1;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}

.menu-h2-title {
    margin: 0;
    padding: 0;
    color: #FFF;
    font-family: "Work Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.menu-p-text {
    margin: 0;
    padding: 0;
    color: #919191;
    font-family: "Work Sans";
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.menu-time-div {
    border-top: 2px solid #4E4D4D;
    margin-inline: 1.08rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border-radius: 0 0 1rem 1rem;
    background: #363636;
}

.menu-time-p {
    color: var(--White, var(--Highlight---text-on-highlight, #919191));
    font-family: "Work Sans";
    font-size: 1rem;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
    padding-left: 1.1rem;
}

.menu-leave-app {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 1.08rem;
    justify-content: center;
    align-items: flex-start;
    border-radius: var(----radius-8x, 1rem);
    background: var(--Fields-dark, #363636);
}

.menu-leave-p {
    color: var(--White, var(--Highlight---text-on-highlight, #FFF));
    font-family: "Work Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}