.title-interaction {
    color: #FFF;
    font-family: "Work Sans";
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
    margin-top: 4px;
}

.p-interaction {
    color: #919191;
    text-align: center;
    font-family: "Work Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 3px;
}