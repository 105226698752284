.app-background {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #1B1B1C;
    min-height: 100dvh;
    margin: 0;
}

.app-background-black {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #0C0C0C;
    width: 100dvw;
    width: auto;
    min-height: 100dvh;
    margin: 0 auto;
}