.interaction-header {
    display: flex;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-inline: 2rem;
    justify-content: space-between;
    align-items: center;
    height: 2.80rem;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    z-index: 1000;
    background-color: #1B1B1C;
    margin-top: -3px;
}


.logo-header {
    width: 5.13644rem;
    height: 1.3125rem;
    flex-shrink: 0;
    margin-top: -1%;
}

.interaction-header-icon-button {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;
    position: relative;

}


.header-title-p {
    color: var(--White, #FFF);
    font-family: "Work Sans";
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
}