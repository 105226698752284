.square-container {
    display: flex;
    padding: 0.5rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 0.5rem;
    background: #4E4D4D;
}

.square-container-long {
    display: flex;
    padding-inline: 0.5rem;
    padding-top: 1.1rem;
    padding-bottom: 1.1rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 0.5rem;
    background: #4E4D4D;
}