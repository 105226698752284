.user-form-gender {
    display: flex;
    flex-direction: column;
    gap: 0.81rem;
}

.radio-input-label {
    font-family: "Work Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    align-items: center;
    color: #919191;
    gap: 0.2rem;
}
.radio-input-label.checked {
    color: #fff;
}

.radio-input-option {
    width: 1.5rem;
    height: 1.5rem;
    appearance: none;
    background-color: #1B1B1C;
    border: 3px solid #919191;
    border-radius: 50%;
    /* outline: none; */
    position: relative;
}

.radio-input-option:not(:checked) {
    accent-color: #919191;
    border-color: #919191;
}

.radio-input-option:checked {
    border-color: #f06292;
}

.radio-input-option:checked::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 50%;
    background-color: #f06292;
}