.title-interaction {
    color: #FFF;
    font-family: "Work Sans";
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
    margin-top: 6px;
}

.send-interaction-photo {
    width: 100%;
    object-fit: cover;
    border-radius: 0.75rem;
}

.send-interaction-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    margin-top: -2rem;
    margin-bottom: 2rem;
    width: 80%;
}

.send-interaction-main-div {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin-bottom: 15;
    height: 100dvh;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
}


.send-interaction-main-div::-webkit-scrollbar {
    width: 0;
    height: 0;
    display: none;
}


.send-interaction-text-p {
    color: var(--White, var(--Highlight---text-on-highlight, #FFF));
    text-align: center;
    font-family: "Work Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: -0.5rem;
}

.send-interaction-button-div {
    background-color: #1B1B1C;
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 0.5rem;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    padding-top: 10px;
    padding-bottom: 1.5rem;
}