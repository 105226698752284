.room-main-div {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    height: 100dvh;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    margin-bottom: 10;
    width: 100%;
}

.room-main-div::-webkit-scrollbar {
    width: 0;
    height: 0;
    display: none;
}



.grid-container {
    /* margin-top: 4.2rem; */
    margin-bottom: 1.5rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    width: 92%;
}