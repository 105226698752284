
html,
body,
#root {
  background: #1B1B1C;
  min-height: 100dvh;
  margin: 0;
  max-width: 100dvw;
  overflow: auto;
  overflow-x: hidden;
} 

/* 
.App {
  text-align: center;
  
  min-height: 100dvh;
  margin: 0;
  max-width: 100dvw;
  overflow: auto;
  overflow-x: hidden;
}

.App-logo {
  height: 35vmin;
  max-width: 84%;
  pointer-events: none;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  max-width: 100dvw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.app-incoming-p {
  font-family: "Work Sans";
  font-style: normal;
  line-height: normal;
  max-width: 90%;
}

.app-a-pesquisa{
  color: #FFF;
}
.app-a-pesquisa:hover {
  text-decoration: underline;
  color: #FF6D8B;

} */