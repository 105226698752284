.app-header {
    display: flex;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-inline: 2rem;
    justify-content: space-between;
    align-items: center;
    height: 2.80rem;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    width: 92%;
    z-index: 1000;
    background-color: #1B1B1C;
}

.app-header-black {
    display: flex;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-inline: 2rem;
    justify-content: space-between;
    align-items: center;
    height: 2.80rem;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    width: 92%;
    z-index: 1000;
    background-color: #0C0C0C;
    max-width: 27rem;
}

.logo-header {
    width: 5.13644rem;
    height: 1.3125rem;
    flex-shrink: 0;
    margin-top: -1%;
}

.app-header-icon-button {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;
    position: relative;
}

