.instructions-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
}


.instructions-main-p {
    margin: 0;
    align-self: stretch;
    color: #FFF;
    font-family: "Work Sans";
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 131%;
}

.how-it-works-container{
    display: flex;
    padding: 1rem 0.75rem;
    margin-bottom: 1rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
    align-self: stretch;
    border-radius: 0.5rem;
    background: var(--BG-RESPOSTAS, #262627);
}

.instructions-items-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(----ws-4x, 1.1rem);
    align-self: stretch;
}

.instructions-item {
    display: flex;
    align-items: flex-start;
    gap: 0.5rem;
}

.instructions-item-number {
    color: var(--Background, #1B1B1C);
    font-family: "Work Sans";
    font-size: 1.15rem;
    font-style: normal;
    font-weight: 700;
    line-height: 131%;
    flex-shrink: 0;
}

.instructions-item-p {
    margin: 0;
    color: var(--White, var(--Highlight---text-on-highlight, #FFF));
    font-family: "Work Sans";
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 131%;
    display: flex;
    align-items: center;
    letter-spacing: 0.2px;
}

.instructions-item-background {
    display: flex;
    width: 0.5rem;
    height: 0.5rem;
    padding: 0.625rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 31.25rem;
    background: var(--Blinker-Pink, #EB7A91);
}

.instructions-subtitle-p {
    margin: 0;
    color: var(--White, var(--Highlight---text-on-highlight, #FFF));
    font-family: "Work Sans";
    font-size: 0.975rem;
    font-style: italic;
    font-weight: 400;
    line-height: 131%;
}

.instructions-tips-container {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    padding: var(----ws-4x, 1rem);
    align-items: flex-start;
    gap: 0.825rem;
    align-self: stretch;
    border-radius: var(----radius-8x, 1rem);
    background: var(--Fields, #393939);
}

.instructions-tip-p {
    margin: 0;
    align-self: stretch;
    color: var(--White, var(--Highlight---text-on-highlight, #FFF));
    font-family: "Work Sans";
    font-size: 0.975rem;
    font-style: normal;
    font-weight: 400;
    line-height: 131%;
}