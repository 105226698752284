.take-selfie-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.actions {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
}

.image-container {
    display: flex;
    justify-content: center;
    position: relative;
    max-width: 425px;
    margin-bottom: 1rem;
}

.selfie-image {
    width: 75%;
    height: auto;
    border-radius: 1rem;
    object-fit: cover;
}

.close-button {
    position: absolute;
    top: -1rem;
    right: 1.65rem;
    background: none;
    border: none;
    cursor: pointer;
    display: inline-flex;
    padding: 0.5rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 31.25rem;
    background: #FF6D8B;
}

.close-button img {
    width: 1.5rem;
    height: 1.5rem;
}

.camera-modal-title {
    align-self: stretch;
    color: var(--White, var(--Highlight---text-on-highlight, #FFF));
    text-align: center;
    font-family: "Work Sans";
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 1rem;
}


.camera-modal-main-div {
    display: flex;
    width: 85%;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1.75rem;
}

.camera-button {
    display: flex;
    height: 11rem;
    padding: 1rem 1rem 1rem 0.75rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    border-radius: 1rem;
    border: 2px dashed #FF6D8B;
    background: #0C0C0C;
    cursor: pointer;
}

.camera-icon {
    width: 2.75rem;
    height: 2.6875rem;
    flex-shrink: 0;
    cursor: pointer;
}

.camera-text {
    margin: 0;
    color: #FF6D8B;
    font-family: "Work Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
}

.take-selfie-remake-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.44rem;
    margin-bottom: 1.5rem;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}

.take-selfie-remake-photo {
    margin: 0;
    color: var(--Gray, #919191);
    font-family: "Work Sans";
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}