.checkbox-input {
    /* Remove o estilo padrão do checkbox */
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;

    /* Define o tamanho do checkbox */
    width: 20px;
    height: 20px;

    /* Define a borda cinza quando desmarcado */
    border: 2px solid #ccc;
    border-radius: 4px;
    /* Borda arredondada */

    /* Remove o fundo */
    background-color: transparent;

    /* Posiciona o conteúdo do checkbox (o check) */
    display: inline-flex;
    align-items: center;
    justify-content: center;

    /* Aumenta o tamanho do checkbox */
    transform: scale(1.1);
    /* Aumenta o tamanho em 20% */
}

/* Estilo quando o checkbox está marcado */
.checkbox-input:checked {
    /* Define a borda rosa quando marcado */
    border-color: #EB7A91;
    /* Define a cor do check (ícone) como rosa */
    background-color: transparent;
}

/* Estilo do ícone de check (pode ser um SVG ou um pseudo-elemento) */
.checkbox-input:checked::before {
    content: "✔";
    /* Usa um símbolo de check */
    color: #EB7A91;
    /* Cor do check */
    font-size: 14px;
    /* Tamanho do check */
}