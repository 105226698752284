.photo-container {
    position: relative;
    margin-bottom: 0.5rem;
    border-radius: 0.75rem;
    overflow: hidden;
    max-height: 13.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.photo {
    object-fit: cover;
    width: 100%;
    height: auto;
    border-radius: 0.75rem;
}


.caption {
    display: flex;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 17%;
    padding-left: 0.625rem;
    align-items: center;
    border-radius: 0rem 0rem 0.75rem 0.75rem;
    background: rgba(12, 12, 12, 0.70);
    box-sizing: border-box;
    color: #FFF;
    font-family: "Work Sans";
    font-size: 0.875rem;
    font-weight: 500;
}