.notifications-main-div {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    height: 100dvh;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.notifications-main-div::-webkit-scrollbar {
    width: 0;
    height: 0;
    display: none;
}

.notification-list {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 1.4rem;
    margin-bottom: 1.5rem;
    width: 90dvw;
}