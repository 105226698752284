.default-loading-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    align-self: stretch;
}

.loading-spinner-p {
    margin: 0;
    align-self: stretch;
    color: #FFF !important;
    text-align: center;
    font-family: "Work Sans";
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.default-loading-div img {
    animation: spin 3s linear infinite;
}