.item-container {
    display: flex;
    /* width: 90%; */
    padding: 1rem 1rem 1rem 0.75rem;
    justify-content: space-between;
    align-items: center;
    border-radius: 1rem;
    background: #363636;
}

.item-container-closed {
    display: flex;
    /* width: 90%; */
    padding: 1rem 1rem 1rem 0.75rem;
    justify-content: space-between;
    align-items: center;
    border-radius: 1rem;
    background: #363636;
    opacity: 50%;
}

.center-div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.25rem;
    flex-shrink: 0;
    margin-left: 1rem;
    flex: 1;
}

.h2-title {
    margin: 0;
    padding: 0;
    color: #FFF;
    font-family: "Work Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.p-text {
    margin: 0;
    padding: 0;
    color: #919191;
    font-family: "Work Sans";
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.open-p {
    color: var(--White, #FFF);
    margin: 0;
    padding: 0;
    font-family: "Work Sans";
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}