.title-out-range {
    text-align: center;
    font-family: "Work Sans";
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    color: #FFF;
    line-height: normal;
}

.text-out-range {
    color: var(--Gray, #919191);
    text-align: center;
    font-family: "Work Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}