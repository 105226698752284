.search-input-container {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.search-icon {
    position: absolute;
    padding: 1rem;
}

.search-input {
    padding: 1rem;
    padding-left: 3rem;
    width: 100%;
    border: 1px solid var(--Field-Active, #4E4D4D);
    border-radius: 1rem;
    background: var(--Fields, #393939);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    outline: none;
    color: #FFF;
}

.search-input.has-value {
    background: var(--Field-Active, #4E4D4D);
    box-shadow: inset 0 0 0 1px var(--White, #FFF);
}