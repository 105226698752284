.filter-room-main-div {
    display: flex;
    margin-top: 4rem;
    justify-content: center;
}

.filter-room-div {
    display: flex;
    width: 92%;
    border-radius: 0.25rem;
    overflow: hidden;
}

.filter-room-button {
    flex: 1;
    text-wrap: nowrap;
    margin: 0;
    height: 2.5rem;
    background-color: #363636;
    color: #919191;
    border: none;
    font-family: "Work Sans";
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.filter-room-button.selected {
    background-color: #FF6978;
    border-radius: 0.25rem;
    color: #1B1B1C;
    font-weight: 700;
}