.default-button {
    color: #1B1B1C;
    font-family: "Work Sans";
    font-size: 1.125rem;
    font-weight: 700;
    display: flex;
    height: 3.375rem;
    padding: 0.625rem 1rem;
    justify-content: space-between;
    align-items: center;
    border-radius: 1rem;
    background: #FF6D8B;
    border: none;
    cursor: pointer;
    width: 100%;
    flex-grow: 0;
    outline: none;
}


.default-button:disabled {
    color: #1B1B1C;
    background: #4E4D4D;
    cursor: not-allowed;
}