.modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: transparent;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  transition: bottom 0.3s ease, visibility 0.3s, opacity 0.3s;
  visibility: hidden;
  opacity: 0;
}

.modal.show {
  visibility: visible;
  opacity: 1;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #1B1B1C;
  border-radius: 2rem 2rem 0rem 0rem;
  padding: 1rem;
  width: 90%;
  z-index: 2;
  transition: bottom 0.3s ease, transform 0.3s ease;
}

.modal-close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  cursor: pointer;
  display: inline-flex;
  padding: 0.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 31.25rem;
  /* background: #FF6D8B; */
}