.user-form-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    margin-top: -10%;
}

.form-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
    max-width: 100dvw;
}

.form-group {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}


.form-actions {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}

.form-span {
    margin: 0;
    padding: 0;
    color: #919191;
    font-family: "Work Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.form-span-white {
    margin: 0;
    padding: 0;
    color: #FFF;
    font-family: "Work Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}