.empty-room-title-p {
    margin: 0;
    align-self: stretch;
    color: var(--Gray, #919191);
    text-align: center;
    font-family: "Work Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.empty-room-text-p {
    margin: 0;
    height: 4.25rem;
    align-self: stretch;
    color: var(--Gray, #919191);
    text-align: center;
    font-family: "Work Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-inline: 17%;
}

.empty-room-div {
    display: flex;
    width: 17.125rem;
    flex-direction: column;
    align-items: center;
    gap: 0.75rem;
    width: 100%;
}