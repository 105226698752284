.interaction-photo {
    width: 100%;
    object-fit: cover;
    border-radius: 0.75rem;
}

.interaction-photo-div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 4rem;
    overflow: hidden;
    border-radius: 0.75rem;
    width: 13.45rem;
    max-height: 18.75rem;
}

.retribution-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -3%;
    padding-top: 1rem;
    background-color: #262627;
    margin-bottom: 4rem;
}

.retribution-p {
    align-self: stretch;
    color: var(--White, #FFF);
    text-align: center;
    font-family: "Work Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 3rem;
}

.retribution-icon-div {
    display: flex;
    align-items: flex-start;
    gap: 2.0625rem;
    margin-bottom: 1.5rem;
}

.emoji-div-absolute {
    margin-top: -20%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5.3125rem;
    height: 5.3125rem;
    border-radius: 31.25rem;
    background: #1B1B1C;
}

.photo-emoji {
    width: 5rem;
    height: 5rem;
}

.received-emoji-text {
    color: #FFF;
    width: 80%;
    font-family: "Work Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    color: '#919191';
    margin: 0;
    margin-top: -8%;
}

.view-interaction-buttons-div {
    background-color: #1B1B1C;
    display: flex;
    width: 100%;
    padding-inline: 3rem;
    flex-direction: column;
    gap: 0.5rem;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    padding-top: 10px;
    padding-bottom: 1.5rem;
}

.view-interaction-bar {
    width: 100%;
    height: 0.6875rem;
    flex-shrink: 0;
    background-color: #393939;
}

.view-interaction-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    margin-top: -2rem;
    margin-bottom: 1.5rem;
    width: 80%;
}

.view-interaction-main-div {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin-bottom: 15;
    height: 100dvh;
    overflow: auto;

    -ms-overflow-style: none;
    scrollbar-width: none;
}

.view-interaction-main-div::-webkit-scrollbar {
    width: 0;
    height: 0;
    display: none;
}