.form-input {
    display: flex;
    height: 2.275rem;
    padding: 0.625rem 1rem;
    border-radius: 1rem;
    border: none;
    background: #4E4D4D;
    color: #FFF;
    font-family: "Work Sans";
    font-size: 1rem;
    font-weight: 400;
}

.form-input-textarea {
    display: flex;
    /* height: 2.275rem; */
    padding: 0.625rem 1rem;
    border-radius: 1rem;
    border: none;
    background: #4E4D4D;
    color: #FFF;
    font-family: "Work Sans";
    font-size: 1rem;
    font-weight: 400;
}

.form-input.has-value {
    background: var(--Field-Active, #4E4D4D);
    box-shadow: inset 0 0 0 1px var(--White, #FFF); /* Borda interna */
}

.form-input.center-text {
    text-align: center;
    letter-spacing: 5px;
}

.form-input::placeholder,
.form-input-textarea::placeholder {
    color: #919191;
}


.form-input:focus,
.form-input-textarea:focus {
    outline: none;
    color: #FFF;
    font-family: "Work Sans";
}

.form-input:-webkit-autofill,
.form-input:-webkit-autofill:hover,
.form-input:-webkit-autofill:focus,
.form-input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #4E4D4D inset !important;
    -webkit-text-fill-color: #FFF !important;
}