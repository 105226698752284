.checkin-background {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #1B1B1C;
    min-width: 100dvw;
    width: auto;
    min-height: 100dvh;
    height: auto;
    box-sizing: border-box;
}

.image-div {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5625rem;
    margin-top: 2.5rem;
    margin-bottom: 3.26rem;
}

.logo {
    margin: 0;
    padding: 0;
    width: 8.875rem;
    height: 2.2678rem;
    flex-shrink: 0;
}

.div-beta {
    display: flex;
    height: 0.625rem;
    padding: 0.100rem 0.475rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 31.25rem;
    background: #919191;
}

.p-beta {
    color: #1B1B1C;
    font-family: "Work Sans";
    font-size: 0.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: 0.15rem;
}