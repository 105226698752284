.notification-item-container-closed {
    display: flex;
    width: 92%;
    padding: 1rem 1rem 1rem 0.75rem;
    justify-content: space-between;
    align-items: center;
    border-radius: 1rem;
    background: #363636;
}

.notification-item-container-opened {
    display: flex;
    width: 92%;
    padding: 1rem 1rem 1rem 0.75rem;
    justify-content: space-between;
    align-items: center;
    border-radius: 1rem;
    background: #0C0C0C;
}

.center-div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.25rem;
    flex-shrink: 0;
    margin-left: 1rem;
    flex: 1;
    margin-right: 0.5rem;

}

.h2-title-opened {
    margin: 0;
    padding: 0;
    color: var(--White, #FFF);
    font-family: "Work Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.h2-title-closed {
    margin: 0;
    padding: 0;
    color: var(--Gray, #919191);
    font-family: "Work Sans";
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.p-text {
    margin: 0;
    padding: 0;
    color: var(--White, #FFF);
    font-family: "Work Sans";
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.image-size-closed {
    width: 1.78125rem;
    height: 1.21763rem;
}

.image-size-opened {
    width: 2.775rem;
    height: auto;
    object-fit: cover;
}

.icon-div-closed {
    display: flex;
    width: 2.75rem;
    height: 2.75rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 31.25rem;
    background: #FF6D8B;
}

.icon-div-opened {
    display: flex;
    width: 2.75rem;
    height: 2.75rem;
    justify-content: center;
    align-items: center;
    border-radius: 31.25rem;
    background: #4E4D4D;
    border-radius: 50%;
    overflow: hidden;
}


.div-time {
    height: 2.2rem;
}

.p-time-closed {
    margin: 0;
    padding: 0;
    color: #FFF;
    font-family: "Work Sans";
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.p-time-opened {
    margin: 0;
    padding: 0;
    color: #919191;
    font-family: "Work Sans";
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}